export default {
  system: {
    name: 'Students Attendance System',
  },
  general: {
    ok: 'OK',
    save: '保存',
    delete: '削除',
    cancel: 'キャンセル',
    admin: '管理者',
    teacher: '教員',
    student: '学生',
    students: '学生',
    name: '名前',
    add: '追加',
    edit: '編集',
    no_option: 'オプションがありません',
    account: 'アカウント',
    password: 'パスワード',
    monday: '月曜日',
    tuesday: '火曜日',
    wednesday: '水曜日',
    thursday: '木曜日',
    friday: '金曜日',
    saturday: '土曜日',
    sunday: '日曜日',
    loading: 'ローディング中',
    success: '成功',
    upload: 'アップロード',
    login: 'ログイン',
    logout: 'ログアウト',
    update_success: 'アップデート成功',
    update_fail: 'アップデート失敗',
    delete_success: '削除成功',
    security_key_name: 'セキュリティキーの名前',
    security_key: 'セキュリティキー',
    login_record: 'ログインの記録',
    signin_record: 'サインインの記録',
    add_time: '時間の追加',
    actions: 'アクション',
    detail: '詳細',
    continue: '続ける',
    export: 'エクスポート',
    id: 'ID',
    view: '詳細',
    minutes: '分',
    min: '分',
    export_record: '記録をエクスポート',
    time: '時間',
    signed_in_time: 'サインインした時間',
    ip: 'IP',
    key_used: '使用されたセキュリティキー',
    back_list: 'リストに戻る',
    class: '授業',
    import: 'インポート',
    result: '結果',
  },
  class: {
    info_category: '授業の種類, Ex: Basic',
    info_no: '授業ID, Ex: SEM100J',
    info_name: '授業名',
    info_late_after: '遅刻',
    info_absent_after: '欠席',

    category: 'カテゴリー',
    class_no: '授業番号.',
    campusName: 'キャンパス名',
    date: '日付',
    day: '曜日',
    period: '時限',
    name: '授業名称',
    teacherName: '教員名',
    lateAfter: '遅刻',
    absenceAfter: '欠席',

    sign_session: 'サインセッション',
    sign_start_time: '開始時間',
    sign_students: 'サインインした学生',
    sign_record: 'サインインの記録',

    delete_class: '授業の削除',
    delete_class_msg: '本当に{name}の授業を削除しますか?',

    categoryEmptyErr: 'カテゴリーは空欄にできません',
    nameEmptyErr: '授業名は空欄にできません',
    dateEmptyErr: '日付は空欄にできません',
    campusEmptyErr: 'キャンパス名は空欄にできません',
    periodEmptyErr: '時限は空欄にできません',
    classNoEmptyErr: '授業番号は空欄にできません',
    teacherEmptyErr: '教員名は空欄にできません',
    lateAfterBiggerThenAbsentErr: 'これは不在時間より大きくすることはできません',

    import_class: '授業のインポート',
    import_success_msg: '授業のインポート{total}',
    import_fail_msg: '{total}授業のインポートに失敗{fail}.',

    add_class: '授業の追加',
    add_class_fail: '授業の追加が失敗しました',
    class_information: '授業情報',

    edit_class: '授業の編集',

    edit_status: 'ステータスの編集',
    end_edit_status: '編集を終了',

    group_by_date: '日付でグループ化',
    group_by_student: '学生でグループ化',

    attend_students: '在籍中の学生',
    edit_students: '学生の編集',
    no_students: '学生がいません',
  },
  signin: {
    attend: '出席',
    late: '遅刻',
    absent: '欠席',
    signed_but_absent: 'サインインしたが欠席',
    not_sign_in: 'サインイン無し',
    start_sign_in: 'サインインする',
    signin_user_success: '{user}サインイン成功',

    classes: '授業',
    sign_in: 'サインイン',
    end_sign_in: 'サインイン終了',
    start_fido: 'FIDO開始',
    list_of_signed_student: 'サインインした学生のリスト',
    view_in_new_window: '新しいウィンドウで見る',
    number_of_student_sign_in: '{num} サインインを済ませた学生数',
  },
  user: {
    info_name: '名前',
    info_type: '種類',
    info_id: 'ID',
    info_password: 'パスワード',
    new_password: '新しいパスワード',
    check_password: 'パスワードを確認する',
    chagne_password: 'パスワードを変更する',

    update_password_fail: 'パスワードの更新に失敗',
    update_password_success: 'パスワードの更新に成功',
    check_password_not_equal: 'パスワードが同じではありません',

    add_user_fail: 'ユーザーの追加に失敗',
    add_user: 'ユーザーの追加',
    add_user_btn: 'ユーザーの追加',

    name_empty_err: '名前は空欄にできません',
    id_empty_err: 'IDは空欄にできません',
    password_empty_err: 'パスワードは空欄にできません',
    import_user: 'ユーザーをインポート',
    delete_user: 'ユーザーを削除',
    delete_user_msg: '本当に{name}のユーザーを削除しますか',
    import_success_msg: '{total}ユーザーをインポート',
    import_fail_msg: '{total}ユーザーのインポートに失敗{fail}.',
    delete_key: 'キーを削除',
    delete_key_msg: '本当にキーと{name}その名前を削除しますか？',

    update_key: 'キーの更新',
    add_security_key: 'セキュリティキーの追加',
    login_with_account: 'アカウントでログイン',
    first_add_key_desc: 'このアカウントは初めてログインするアカウントです。セキュリティキーをこのユーザーに追加してください',
    first_change_pass_desc: 'このアカウントは初めてログインするアカウントです。パスワードをこのユーザーに編集してください',
    get_security_key_again: 'もう一度セキュリティキーをつなぐ',
    user_information: 'ユーザー情報',
    too_many_record: '{num}件の記録があります, 最新の{limit}件のレコードのみを表示',
  },
  page: {
    classes: '授業',
    periods: '時限',
    users: 'ユーザー',
    profile: 'プロフィール',
  },
  campus: {
    periods: '時限',
    period: '時限',
    name_info: 'キャンパス名を入力してください',
    add_campus: 'キャンパスを追加',
    start_time: '開始時間',
    end_time: '終了時間',
    operation: 'オペレーション',
  },
  error: {
    login_fail: 'ログイン失敗',
    sign_fail: 'サインイン失敗',
    user_not_allowed_auth: 'このユーザーは許可されていません',
    user_id_exist: 'ユーザーIDは既に存在しています',
    class_id_duplicate: '授業IDは既に存在しています',
    not_find_user: '教員が存在しません',
    class_info_not_valid: '授業情報が無効です',
    campus_exist: 'キャンパスが存在します',
    campus_classes_not_empty: '授業がある限りキャンパスの削除はできません',
    invalid_periods: '時限の終了時間は開始時間よりも後にする必要があります',
    invalid_access: 'セッションの有効期限が切れ',
    token_expire: 'セッションの有効期限が切れ',
    no_more_period: 'これ以上時限を追加できません',
  },
  fido: {
    create_key_error: 'キーの作成に失敗しました',
    create_key_success: 'キーの作成に成功しました',
    update_key_name: '{name}に名前を変更',
    update_key_name_fail: '{name}の名前変更に失敗しました。現在の名前を使用してください。',
    security_key_setting: 'セキュリティキーの設定',
    already_existed_err: 'セキュリティキーはすでに登録されています',
  },
  file: {
    choose_import_file: 'インポートファイルを選択',
    select_csv: 'csvファイルを選択',
    err_csv_file_not_choose: 'csvファイルを選択してください',
  },
};
