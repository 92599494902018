export default {
  system: {
    name: 'Students Attendance System',
  },
  general: {
    ok: 'OK',
    save: 'Save',
    delete: 'Delete',
    cancel: 'Cancel',
    admin: 'Admin',
    teacher: 'Teacher',
    student: 'Student',
    students: 'Students',
    name: 'Name',
    add: 'Add',
    edit: 'Edit',
    no_option: 'No Options',
    account: 'Account',
    password: 'Password',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    loading: 'Loading',
    success: 'Success',
    upload: 'Upload',
    login: 'Login',
    logout: 'Logout',
    update_success: 'Update Success',
    update_fail: 'Update Fail',
    delete_success: 'Delete Success',
    security_key_name: 'Security Key Name',
    security_key: 'Security Keys',
    login_record: 'Login Record',
    signin_record: 'Sign-in Record',
    add_time: 'Add Time',
    actions: 'Actions',
    detail: 'Detail',
    continue: 'Continue',
    export: 'Export',
    id: 'ID',
    view: 'View',
    minutes: 'Minutes',
    min: 'minutes',
    export_record: 'Export Record',
    time: 'Time',
    signed_in_time: 'Signed-in Time',
    ip: 'IP',
    key_used: 'Security Key Used',
    back_list: 'Back to list',
    class: 'Class',
    import: 'Import',
    result: 'Result',
  },
  class: {
    info_category: 'Class category, Ex: Basic',
    info_no: 'Class ID, Ex: SEM100J',
    info_name: 'Name of class',
    info_late_after: 'Late After',
    info_absent_after: 'Absent After',

    category: 'Category',
    class_no: 'Class No.',
    campusName: 'Campus',
    date: 'Date',
    day: 'Day',
    period: 'Period',
    name: 'Name',
    teacherName: 'Teacher',
    lateAfter: 'Late After',
    absenceAfter: 'Absent After',

    sign_session: 'Sign Session',
    sign_start_time: 'Start Time',
    sign_students: 'Signed Students',
    sign_record: 'Record of Sign-in',

    delete_class: 'Delete Class',
    delete_class_msg: 'Sure to delete class {name}?',

    categoryEmptyErr: 'Category cannot be empty',
    nameEmptyErr: 'Name cannot be empty',
    dateEmptyErr: 'Date cannot be empty',
    campusEmptyErr: 'Campus cannot be empty',
    periodEmptyErr: 'Period cannot be empty',
    classNoEmptyErr: 'ClassNo cannot be empty',
    teacherEmptyErr: 'Teacher cannot be empty',
    lateAfterBiggerThenAbsentErr: 'Late After cannot be bigger than absent time',

    import_class: 'Import Classes',
    import_success_msg: 'Import {total} classes',
    import_fail_msg: 'Import {total} classes, fail {fail}.',

    add_class: 'Add Class',
    add_class_fail: 'Add Class Fail',
    class_information: 'Class Information',

    edit_class: 'Edit Class',

    edit_status: 'Edit Status',
    end_edit_status: 'End Edit',

    group_by_date: 'Group by date',
    group_by_student: 'Group by student',

    attend_students: 'Attend Students',
    edit_students: 'Edit Students',
    no_students: 'No Students',
  },
  signin: {
    attend: 'Attend',
    late: 'Late',
    absent: 'Absent',
    signed_but_absent: 'Signed but absent',
    not_sign_in: 'Not Signed In',
    start_sign_in: 'Start Signed-in',
    signin_user_success: '{user} sign in successfully',

    classes: 'Classes',
    sign_in: 'Sign-In',
    end_sign_in: 'End Sign-In',
    start_fido: 'Start FIDO',
    list_of_signed_student: 'List of Signed Student',
    view_in_new_window: 'View in new window',
    number_of_student_sign_in: '{num} students finish sign-in',
  },
  user: {
    info_name: 'Name',
    info_type: 'Type',
    info_id: 'ID',
    info_password: 'Password',
    new_password: 'New Password',
    check_password: 'Check Password',
    chagne_password: 'Change Password',

    update_password_fail: 'Update password fail',
    update_password_success: 'Update password success',
    check_password_not_equal: 'Passwords is not equal',

    add_user_fail: 'Add user fail',
    add_user: 'Add User',
    add_user_btn: 'Add User',

    name_empty_err: 'Name cannot be empty',
    id_empty_err: 'ID cannot be empty',
    password_empty_err: 'Password cannot be empty',
    import_user: 'Import Users',
    delete_user: 'Delete User',
    delete_user_msg: 'Sure to delete user {name}',
    import_success_msg: 'Import {total} users',
    import_fail_msg: 'Import {total} users, fail {fail}.',
    delete_key: 'Delete Key',
    delete_key_msg: 'Sure to delete key with name {name}',

    update_key: 'Update Key',
    add_security_key: 'Add Security Key',
    login_with_account: 'Login with Account',
    first_add_key_desc: 'This is first time login with this account, please add a security key for this user',
    first_change_pass_desc: 'This is first time login with this account, please edit password for this user',
    get_security_key_again: 'Get security key again',
    user_information: 'User Information',
    too_many_record: 'There are {num} records, show latest {limit} records only.',
  },
  page: {
    classes: 'Classes',
    periods: 'Periods',
    users: 'Users',
    profile: 'Profile',
  },
  campus: {
    periods: 'Periods',
    period: 'Period',
    name_info: 'Please input name of campus',
    add_campus: 'Add Campus',
    start_time: 'Start Time',
    end_time: 'End Time',
    operation: 'Operation',
  },
  error: {
    login_fail: 'Login fail',
    sign_fail: 'Sign in fail',
    user_not_allowed_auth: 'Not Allowed',
    user_id_exist: 'User ID is existed',
    class_id_duplicate: 'Class ID is existed',
    not_find_user: 'Teacher is not existed',
    class_info_not_valid: 'Class info is invalid',
    campus_exist: 'Campus is existed',
    campus_classes_not_empty: 'Campus cannot delete when it has classes',
    invalid_periods: 'Period end time must be later than start time and cannot overlap other periods',
    invalid_access: 'Session expired',
    token_expire: 'Session expired',
    no_more_period: 'Cannot add more period',
  },
  fido: {
    create_key_error: 'Create key fail',
    create_key_success: 'Create key success',
    update_key_name: 'Change name to {name}',
    update_key_name_fail: 'Change namt to "{name}" fail, use origin name',
    security_key_setting: 'Security key setting',
    already_existed_err: 'Security Key already registered',
  },
  file: {
    choose_import_file: 'Choose import file',
    select_csv: 'Select csv file',
    err_csv_file_not_choose: 'Please choose a csv file',
  },
};
